/* You can add global styles to this file, and also import other style files */
body {
    overflow-x: hidden;
}

.logo {
    margin: 6em;
    width: 12em;
}

a {
    color: lightgray !important;
}

a:hover {
    color: lightgray !important;
}

::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.oss-label {
    text-align: justify;
}
